/* ==========================================================================
   Variables
   ========================================================================== */

:root {
  /* Colors */

  --color-bg: #121212;
  --color-text: #fff;
  --color-border: #414141;
  --color-subdued: #909090;
  --color-primary: #e27533;
  --color-danger: #f71f2a;
  --color-success: #46d06a;
  --color-warning: #fcc718;

  /* Borders */

  --borderRadius-base: 6px;
  --borderRadius-s: 3px;
  --borderWidth: 1px;

  /* Typography */

  --fontFamily-base: 'Source Sans Pro', -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue',
    sans-serif;

  --fontSize-base: 1rem;
  --fontSize-xs: 0.625rem;
  --fontSize-s: 0.75rem;
  --fontSize-l: 1.875rem;
  --fontSize-xl: 2.125rem;

  --lineHeight-base: 1.5;
  --lineHeight-heading: 1.2;

  /* Spacing */

  --space: 1.25rem;
  --space-xs: 0.3125rem;
  --space-s: 0.625rem;
  --space-l: 2.5rem;
  --space-xl: 6.25rem;

  /* Miscellaneous */

  --siteWidth: 650px;

  --transitionSpeed-base: 0.3s;
  --transitionSpeed-medium: 0.6s;
  --transitionSpeed-long: 1s;
}

/* ==========================================================================
   Reset
   ========================================================================== */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* ==========================================================================
   Base
   ========================================================================== */

body {
  background-color: var(--color-bg);
  color: var(--color-text);
  font-family: var(--fontFamily-base);
  font-size: var(--fontSize-base);
  line-height: var(--lineHeight-base);
  margin: 0 auto;
  padding: calc(var(--space)) var(--space);
  max-width: var(--siteWidth);
}

/* Headings
   ========================================================================== */

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: var(--lineHeight-heading);
  margin: 0;
}

h1 {
  margin: 0;
  margin-bottom: calc(var(--space) / 2);
}

/* Links
   ========================================================================== */

a {
  color: var(--color-primary);
  transition: var(--transitionSpeed-base) ease-in-out;
}

a:active,
a:focus,
a:hover {
  color: var(--color-text);
}

/* Paragraphs
   ========================================================================== */

p {
  margin: 0;
  margin-bottom: var(--space);
}

/* ==========================================================================
   About
   ========================================================================== */

.About > p:first-child {
  font-size: 1.2rem;
}

.About > ul {
  margin-bottom: var(--space-l);
  margin-left: var(--space);
}

.About > ul > li:not(:last-child) {
  margin-bottom: var(--space-s);
}

/* ==========================================================================
   App
   ========================================================================== */

.App-content,
.App-title {
  opacity: 0;
}

.App.is-loaded .App-content {
  animation: fadeIn var(--transitionSpeed-long) 1;
  animation-fill-mode: forwards;
}

.App.is-loaded .App-title {
  animation: jackInTheBox var(--transitionSpeed-medium)
    var(--transitionSpeed-base) 1;
  animation-fill-mode: forwards;
}

.App-title {
  position: relative;
}

/* ==========================================================================
   Button
   ========================================================================== */

.Button {
  background-color: var(--color-primary);
  border: 0;
  border-radius: 20px;
  color: var(--color-text);
  cursor: pointer;
  display: inline-block;
  font-size: var(--fontSize-xs);
  font-weight: bold;
  letter-spacing: 0.0625rem;
  line-height: 4;
  padding-left: var(--space-l);
  padding-right: var(--space-l);
  text-transform: uppercase;
  transition: var(--transitionSpeed-base) ease-in-out;
}

.Button:active,
.Button:focus,
.Button:hover {
  background-color: var(--color-text);
  color: var(--color-bg);
}

/* ==========================================================================
   ButtonLins
   ========================================================================== */

.ButtonLink {
  background-color: transparent;
  border: 0;
  color: var(--color-primary);
  cursor: pointer;
  font-size: var(--fontSize-s);
  text-decoration: underline;
  transition: var(--transitionSpeed-base) ease-in-out;
}

.ButtonLink:active,
.ButtonLink:focus,
.ButtonLink:hover {
  color: var(--color-text);
  outline: none;
}

/* ==========================================================================
   DiLogo
   ========================================================================== */

.DiLogo {
  display: none;
  opacity: 0.5;
  position: absolute;
  right: var(--space);
  top: var(--space);
  transition: var(--transitionSpeed-base) ease-in-out;
}

.DiLogo:active,
.DiLogo:focus,
.DiLogo:hover {
  opacity: 1;
}

/* ==========================================================================
   FileInput
   ========================================================================== */

.FileInput {
  cursor: pointer;
  display: block;
  height: 2.5rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 20rem;
  position: relative;
}

/* Input
   ========================================================================== */

.FileInput > input {
  margin: 0;
  opacity: 0;
}

/* Custom
   ========================================================================== */

.FileInput-custom {
  background-color: var(--color-bg);
  border-radius: var(--borderRadius-base);
  border: var(--borderWidth) solid var(--color-border);
  color: var(--color-border);
  height: 2.5rem;
  left: 0;
  line-height: 1.25;
  padding: 0.5rem 1rem;
  position: absolute;
  right: 0;
  top: 0;
  user-select: none;
  z-index: 5;
}

/* Before */

.FileInput-custom::before {
  background-color: var(--color-primary);
  border: var(--borderWidth) solid var(--color-primary);
  border-radius: 0 var(--borderRadius-base) var(--borderRadius-base) 0;
  bottom: calc(var(--borderWidth) * -1);
  color: var(--color-text);
  content: 'Browse';
  display: block;
  line-height: 2.25;
  padding-left: 1rem;
  padding-right: 1rem;
  position: absolute;
  right: calc(var(--borderWidth) * -1);
  top: calc(var(--borderWidth) * -1);
  z-index: 6;
}

/* After */

.FileInput-custom::after {
  content: attr(data-value);
}

/* ==========================================================================
   Footer
   ========================================================================== */

.Footer {
  border-top: var(--borderWidth) solid var(--color-border);
  margin-top: var(--space-l);
  padding-top: var(--space-l);
  text-align: center;
}

/* ==========================================================================
   Form
   ========================================================================== */

/* Field
   ========================================================================== */

.Form-field {
  margin-bottom: var(--space-l);
}

/* Input
   ========================================================================== */

.Form-input {
  background-color: transparent;
  border: var(--borderWidth) solid var(--color-border);
  border-radius: var(--borderRadius-base);
  color: var(--color-text);
  font-size: var(--fontSize-base);
  padding: var(--space);
  width: 100%;
}

.Form-input:focus {
  border-color: var(--color-primary);
  outline: none;
}

/* Label
   ========================================================================== */

.Form-label {
  display: block;
  font-size: var(--fontSize-base);
  font-weight: bold;
  margin-bottom: var(--space-xs);
}

/* ==========================================================================
   ItemList
   ========================================================================== */

.ItemList {
  border: var(--borderWidth) solid var(--color-border);
  border-bottom-left-radius: var(--borderRadius-base);
  border-bottom-right-radius: var(--borderRadius-base);
  grid-template-columns: 50% 50%;
  margin-bottom: var(--space);
  overflow: hidden;
}

/* Item
   ========================================================================== */

.ItemList-item {
  border-right: var(--borderWidth) solid var(--color-border);
  border-top: var(--borderWidth) solid var(--color-border);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: calc(var(--borderWidth) * -1);
  margin-top: calc(var(--borderWidth) * -1);
  padding: var(--space);
}

.ItemList-item-label {
  color: var(--color-primary);
  display: block;
  font-size: var(--fontSize-xs);
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.ItemList-item-value {
  display: block;
  font-size: var(--fontSize-l);
  line-height: var(--lineHeight-heading);
  margin-bottom: var(--space-xs);
}

.ItemList-item-reason {
  color: var(--color-subdued);
  display: block;
  font-size: var(--fontSize-s);
  font-style: italic;
  margin: 0;
  position: relative;
  top: -3px;
}

.ItemList-item-reason::before {
  background-color: var(--color-subdued);
  border-radius: 50%;
  content: '';
  display: inline-block;
  height: 8px;
  margin-right: var(--space-xs);
  width: 8px;
}

.ItemList-item-reason--yes::before {
  background-color: var(--color-success);
}

.ItemList-item-reason--no::before {
  background-color: var(--color-warning);
}

/* ==========================================================================
   Loader
   ========================================================================== */

.Loader {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Bar
   ========================================================================== */

.Loader-bar {
  background-color: var(--color-border);
  border-radius: var(--borderRadius-base);
  height: 10px;
  overflow: hidden;
  margin-bottom: var(--space-s);
  position: relative;
  width: 60px;
}

.Loader-bar::before {
  animation: bounce var(--transitionSpeed-base) linear alternate infinite;
  background-color: var(--color-primary);
  border-radius: var(--borderRadius-base);
  content: '';
  display: block;
  height: 10px;
  left: 0;
  position: absolute;
  top: 0;
  width: 30px;
}

/* Text
   ========================================================================== */

.Loader-text {
  color: var(--color-subdued);
  display: block;
  margin: 0;
}

/* ==========================================================================
   Package
   ========================================================================== */

.Package {
  border: var(--borderWidth) solid var(--color-border);
  border-top-left-radius: var(--borderRadius-base);
  border-top-right-radius: var(--borderRadius-base);
  display: flex;
  margin: 0;
  margin-bottom: calc(var(--borderWidth) * -1);
  padding: var(--space);
  position: relative;
}

.Package[data-collapsed='true'] {
  border-bottom-left-radius: var(--borderRadius-base);
  border-bottom-right-radius: var(--borderRadius-base);
}

/* Content
   ========================================================================== */

.Package-content {
  position: relative;
  width: 100%;
}

/* Description
   ========================================================================== */

.Package-description {
  font-size: var(--fontSize-s);
  margin-bottom: 0;
}

/* Icon
   ========================================================================== */

.Package-icon {
  margin-right: var(--space-s);
  position: relative;
  top: 0;
}

.Package-icon--yes {
  color: var(--color-success);
}

.Package-icon--no {
  color: var(--color-warning);
}

/* Links
   ========================================================================== */

.Package-links {
  display: flex;
  list-style-type: none;
  margin-top: var(--space);
}

.Package-links > *:not(:last-child) {
  margin-right: var(--space-s);
}

.Package-links-item {
  display: block;
  filter: grayscale(1);
  opacity: 0.6;
}

.Package-links-item:active,
.Package-links-item:focus,
.Package-links-item:hover {
  filter: grayscale(0);
  opacity: 1;
}

/* Toggle
   ========================================================================== */

.Package-toggle {
  margin-top: var(--space);
}

/* Title
   ========================================================================== */

.Package-title {
  font-size: var(--fontSize);
  padding-right: var(--space-xl);
}

/* ==========================================================================
   PageSubtitle
   ========================================================================== */

.PageSubtitle {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: var(--space-l);
}

.PageSubtitle--s {
  margin-bottom: var(--space-s);
}

/* ==========================================================================
   Tooltip
   ========================================================================== */

.Tooltip {
  background-color: var(--color-text) !important;
  border: 0 !important;
  border-radius: var(--borderRadius-s) !important;
  display: none !important;
}

/* ==========================================================================
   Report
   ========================================================================== */

.Report {
  height: 0;
  margin-top: calc(var(--space) + var(--space-l));
  opacity: 0;
  overflow: hidden;
  transition: var(--transitionSpeed-base) ease-in;
  visibility: hidden;
}

.Report[data-complete='true'] {
  height: auto;
  opacity: 1;
  overflow: visible;
  visibility: visible;
}

/* ==========================================================================
   Search
   ========================================================================== */

.Search {
  margin-bottom: var(--space-l);
  position: relative;
}

/* Clear
   ========================================================================== */

.Search-clear {
  background-color: transparent;
  border: 0;
  color: var(--color-subdued);
  cursor: pointer;
  position: absolute;
  right: var(--space-s);
  top: calc(50% + 1px);
  transform: translateY(-50%);
  transition: var(--transitionSpeed-base) ease-in-out;
}

.Search-clear:active,
.Search-clear:focus,
.Search-clear:hover {
  color: var(--color-text);
}

/* Form
   ========================================================================== */

.Search-form {
  position: relative;
}

/* Input
   ========================================================================== */

.Search-input {
  background-color: transparent;
  border: 0;
  border: var(--borderWidth) solid var(--color-border);
  border-radius: var(--borderRadius-base);
  color: var(--color-text);
  font-size: var(--fontSize);
  padding: var(--space-s) calc(var(--space-l) + var(--space-xs));
  text-align: center;
  transition: border var(--transitionSpeed-base) ease-in-out;
  width: 100%;
}

.Search-input-description {
  color: var(--color-subdued);
  font-size: var(--fontSize-s);
  margin-top: var(--space-s);
  text-align: center;
}

.Search-input-description a {
  font-weight: bold;
}

.Search-input:active,
.Search-input:focus {
  border-color: var(--color-primary);
  outline: none;
}

/* Results
   ========================================================================== */

.Search-results {
  background-color: var(--color-bg);
  border: var(--borderWidth) solid var(--color-border);
  border-radius: var(--borderRadius-base);
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: calc(100% - var(--space));
  width: 100%;
  z-index: 10;
}

.Search-results-item {
  color: var(--color-subdued);
  display: block;
  line-height: 1;
  padding: var(--space-s) var(--space);
  text-decoration: none;
}

.Search-results-item:active,
.Search-results-item:focus,
.Search-results-item:hover,
.Search-results-item--active {
  background-color: rgba(255, 255, 255, 0.05);
  color: var(--color-subdued);
}

.Search-results-item-name {
  color: var(--color-primary);
  display: block;
  margin-bottom: var(--space-xs);
}

.Search-results-item-description {
  font-size: var(--fontSize-s);
}

.Search-results-item--active .Search-results-item-name {
  color: var(--color-text);
  transition: color var(--transitionSpeed-base) ease-in-out;
}

/* ==========================================================================
   Title
   ========================================================================== */

.Title {
  font-size: var(--fontSize-xl);
  margin-bottom: var(--space);
  text-align: center;
}

.Title > a {
  color: var(--color-text);
  text-decoration: none;
}

/* ==========================================================================
   Utilities
   ========================================================================== */

/* Color
   ========================================================================== */

.U-c-primary {
  color: var(--color-primary);
}

.U-c-subdued {
  color: var(--color-subdued);
}

/* Margin
   ========================================================================== */

.U-mb-s {
  margin-bottom: var(--space-s);
}

.U-mb-l {
  margin-bottom: var(--space-l);
}

.U-mr-xs {
  margin-right: var(--space-xs);
}

.U-mr-s {
  margin-right: var(--space-s);
}

.U-mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.U-mx-s {
  margin-left: var(--space-s);
  margin-right: var(--space-s);
}

/* ==========================================================================
   Max Width
   ========================================================================== */

.U-mw-25 {
  max-width: 25rem;
}

/* Position
   ========================================================================== */

.U-p-relative {
  position: relative;
}

/* Text Alignment
   ========================================================================== */

.U-ta-center {
  text-align: center;
}

/* ==========================================================================
   Keyframes
   ========================================================================== */

/* Bounce
   ========================================================================== */

@keyframes bounce {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

/* Fade In
   ========================================================================== */

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Jack in the Box
   ========================================================================== */

@keyframes jackInTheBox {
  from {
    opacity: 0;
    transform: scale(0.1) rotate(30deg);
    transform-origin: center bottom;
  }

  50% {
    transform: rotate(-10deg);
  }

  70% {
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* ==========================================================================
   Media Queries
   ========================================================================== */

/* Tablet
   ========================================================================== */

@media screen and (min-width: 48rem) {
  body {
    padding: var(--space-xl) 0;
  }

  .About > p:first-child {
    font-size: 1.6rem;
  }

  .About > ul {
    font-size: 1.2rem;
    margin-left: var(--space-l);
  }

  .App-title::after,
  .DiLogo {
    display: block;
  }

  .ItemList {
    display: grid;
  }

  .Package-description {
    font-size: var(--fontSize-base);
  }

  .Package-icon {
    top: 9px;
  }

  .Package-links {
    margin-top: 0;
    position: absolute;
    right: 0;
    top: 0.375rem;
  }

  .Package-title {
    font-size: var(--fontSize-l);
  }

  .Search-clear {
    right: var(--space);
    top: calc(50% + 2px);
  }

  .Search-header {
    margin-bottom: var(--space-l);
  }

  .Search-input {
    font-size: var(--fontSize-xl);
    padding-bottom: var(--space);
    padding-top: var(--space);
  }

  .Search-label {
    font-size: var(--fontSize-xl);
  }

  .Title {
    font-size: 80px;
    margin-bottom: var(--space-l);
  }

  .Tooltip {
    display: block !important;
  }
}
